import { useMemo } from 'react';

import { useEnvironment } from '../context/EnvironmentContext';

/**
 * Returns default teaser and image modules to be used as fallbacks if some information is missing.
 *
 * This information is used as default or when rendering incomplete data.
 *
 */
export function useDefaults() {
	const { images } = useEnvironment();

	const fallbacks = useMemo(() => {
		const fallbackImageModule = {
			__typename: 'ModuleImage' as const,
			altText: 'Platzhalter Bild',
			copyright: 'BR24',
			title: 'Platzhalter Bild',
			url: images.FALLBACK_IMAGE,
		} as const;

		const fallbackTeaserModule = {
			id: 'fallbackId',
			meta: null,
			copyright: 'BR24',
			title: 'Platzhalter Bild',
			type: 'IMAGE',
			thumbnail: fallbackImageModule,
			__typename: 'Module' as const,
			rowId: 31337,
			audio: null,
			livestream: null,
			video: null,
		} as const;

		return { fallbackImageModule, fallbackTeaserModule };
	}, [images.FALLBACK_IMAGE]);

	return fallbacks;
}
