import { useCallback, useContext, useEffect, useState } from 'react';

import { logger } from '@/utils/logging/logger';
import { useTracktor } from 'react-tracktor';

import MediaContext from '../context/MediaContext';
import { MediaContextActionType, MediaContextPlaybackStatus } from '../context/MediaContext';

type UseMediaContextOptions = {
	isAudio?: boolean;
	isVideo?: boolean;
	shouldTrackPlay?: boolean;
};

let nextId = 0;

/**
 * Tracks the media playback state across the entire application. This coordinates between al media instances
 * such that only one media instance is playing at a time. If another instance starts playing the state of the
 * other instances will be reset correctly.
 *
 * @param options
 * @returns
 */
export function useMediaState({ isAudio, isVideo, shouldTrackPlay }: UseMediaContextOptions = {}) {
	const { trackEvent } = useTracktor({});
	const [instanceId, setInstanceId] = useState<string>('initial-id');

	useEffect(() => {
		setInstanceId(`media_id_${nextId}`);
		nextId = nextId + 1;
	}, []);

	const {
		dispatch,
		state: { currentMediaId, mediaPlaybackStatus },
	} = useContext(MediaContext);

	const mediaPause = useCallback(() => {
		if (!!dispatch) {
			dispatch({ type: MediaContextActionType.Pause, mediaId: instanceId });
		}
	}, [dispatch, instanceId]);

	const mediaStart = useCallback(() => {
		if (!!dispatch) {
			dispatch({ type: MediaContextActionType.Start, mediaId: instanceId });

			if (shouldTrackPlay) {
				if (isAudio) {
					trackEvent({ data: { customVars: { 6: '[Audio-Start]' } }, event: { type: 'PageViewEvent' } });
				} else if (isVideo) {
					trackEvent({ data: { customVars: { 6: '[Video-Start]' } }, event: { type: 'PageViewEvent' } });
				} else if (process.env.NODE_ENV === 'development') {
					logger.warn(`'shouldTrackPlay' is true, but it is not clear whether this is an audio or video.`);
				}
			}
		}
	}, [dispatch, instanceId, isAudio, isVideo, shouldTrackPlay, trackEvent]);

	const mediaStop = useCallback(() => {
		if (!!dispatch) {
			dispatch({ type: MediaContextActionType.Stop, mediaId: instanceId });
		}
	}, [dispatch, instanceId]);

	useEffect(() => {
		return () => {
			if (!!dispatch && currentMediaId === instanceId) {
				dispatch({ type: MediaContextActionType.Reset, mediaId: instanceId });
			}
		};
		// when the component unmounts and the current media ist set to the current instance reset the state.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isPaused = currentMediaId === instanceId && mediaPlaybackStatus === MediaContextPlaybackStatus.PAUSED;
	const isPlaying = currentMediaId === instanceId && mediaPlaybackStatus === MediaContextPlaybackStatus.STARTED;
	const isStopped = currentMediaId !== instanceId || mediaPlaybackStatus === MediaContextPlaybackStatus.STOPPED;

	const isPlayingOrPaused = isPaused || isPlaying;

	return {
		mediaPlaybackStatus: currentMediaId === instanceId ? mediaPlaybackStatus : MediaContextPlaybackStatus.STOPPED,
		isPaused,
		isPlayingOrPaused,
		isPlaying,
		isStopped,
		mediaPause,
		mediaStart,
		mediaStop,
	};
}
