import { useEffect, useRef } from 'react';

// Based on: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef<() => void>();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		const tick = () => {
			if (typeof savedCallback.current === 'function') {
				savedCallback.current();
			}
		};

		if (delay !== null) {
			const intervalId = setInterval(tick, delay);

			return () => clearInterval(intervalId);
		}
		return undefined;
	}, [delay]);
}
