import { BoardSectionItemArticleFragment } from '@/components/Board/Section/Items/__generated__/BoardSectionItemArticle.generated';

// This is bit confusing, but we're typing this manually so we get some sort of typechecking,
// while still being flexible enough so we can call this from anywhere, without having to get all possible module types

const getDurationFromMediaModule = (mediaModule?: BoardSectionItemArticleFragment['teaserModule']['nodes'][0] | null) => {
	if (!mediaModule) {
		return null;
	}

	switch (mediaModule.type) {
		case 'AUDIO':
			const audioDuration = mediaModule?.audio?.duration;

			if (!audioDuration) {
				return null;
			}

			return audioDuration;

		case 'VIDEO':
			const videoUrl = mediaModule?.video?.duration;

			if (!videoUrl) {
				return null;
			}

			return videoUrl;

		default:
			return null;
	}
};

export default getDurationFromMediaModule;
