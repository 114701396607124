import { useCallback, useEffect, useState } from 'react';

interface Meta {
	/**
	 * The version of the protocol
	 */
	version: '1.0.0';
}

interface Message<Type extends string, Payload> {
	type: Type;
	id: string | undefined;
	meta: Meta;
	payload: Payload;
}

interface TypePayloadMap {
	init: void;
	size: { width: number; height: number };
}

type Messages = MessageEvent<Message<'init', TypePayloadMap['init']> | Message<'size', TypePayloadMap['size']>>;

interface UseEmbedSizeResult {
	/**
	 * The current height reported by the embed to the embedding context.
	 */
	height: number | undefined;

	/**
	 * Set to `true` once the event listeners have been installed. This should be used to load the iframe. Otherwise there
	 * is the possibility that the initial events from the iframe will  be lost.
	 */
	isInitialized: boolean;

	/**
	 * Set to `true` once the embed reports as ready.
	 */
	isEmbedInitialized: boolean;
}

export function useEmbedSize(url: string, id: number | string): UseEmbedSizeResult {
	const [height, setHeight] = useState<number | undefined>(undefined);
	const [isEmbedInitialized, setIsEmbedInitialized] = useState(false);
	const [isInitialized, setIsInitialized] = useState(false);
	const embedOrigin = new URL(url).origin;

	const handlePostMessage = useCallback(
		(embedRendererMessage: Messages) => {
			const messageOrigin = embedRendererMessage.origin;

			if (embedOrigin === messageOrigin && id.toString() === embedRendererMessage.data.id) {
				switch (embedRendererMessage.data.type) {
					case 'init':
						setIsEmbedInitialized(true);
						break;
					case 'size':
						setHeight(embedRendererMessage.data.payload.height);
						break;
				}
			}
		},
		[embedOrigin, id]
	);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('message', handlePostMessage, false);
			setIsInitialized(true);
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('message', handlePostMessage, false);
			}
		};
	}, [handlePostMessage]);

	return { height, isInitialized, isEmbedInitialized };
}
