import React from 'react';

type UsePermanentSwitch = [boolean, () => void];

const usePermanentSwitch = (inititalValue = false): UsePermanentSwitch => {
	const [value, setValue] = React.useState<boolean>(inititalValue);
	const [wasSet, setWasSet] = React.useState<boolean>(inititalValue);

	const toggleValue = () => {
		if (!wasSet) {
			setWasSet(true);
			setValue(!value);
		}
	};

	return [value, toggleValue];
};

export default usePermanentSwitch;
