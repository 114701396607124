import { PropsWithChildren } from 'react';

import ArticleItemImage from '@/components/Article/Items/ArticleItemImage';
import { Image } from '@/components/Image/Image';
import { MediaControlButtonVariant } from '@/types/enums';

import { MediaControlButton } from './MediaControlButton';
import styles from './MediaOverlay.module.scss';

interface Props {
	thumbnailTitle?: string;
	thumbnailAltText?: string | null;
	thumbnailCopyright?: string | null;
	thumbnailLink?: string | null;
	thumbnailUrl?: string | null;
	isPriority?: boolean;
	showPlayButton: boolean;
	onPlayButtonClick?: () => void;
	isHero: boolean;
	buttonVariant: MediaControlButtonVariant;
	className?: string;
	enableZoomEffect: boolean;
	sizes: string;
	buttonTitle?: string;
}

export function MediaOverlay({
	thumbnailAltText,
	thumbnailCopyright,
	thumbnailLink,
	thumbnailUrl,
	thumbnailTitle,
	isPriority,
	onPlayButtonClick,
	buttonVariant,
	showPlayButton,
	isHero,
	children,
	className,
	enableZoomEffect,
	sizes,
	buttonTitle,
}: PropsWithChildren<Props>) {
	const image = thumbnailUrl && (
		<Image
			className={styles.mediaImage}
			alt={thumbnailAltText ?? ''}
			copyright={thumbnailCopyright ?? undefined}
			title={thumbnailTitle}
			src={thumbnailUrl}
			isPriority={isPriority}
			enableZoomEffect={enableZoomEffect}
			sizes={sizes}
		/>
	);

	return (
		<div className={`${className ? className : ''}`}>
			{image && thumbnailLink ? <ArticleItemImage image={image} path={thumbnailLink} title={thumbnailTitle ?? ''} /> : <>{image}</>}

			<div className={`${styles.controlsWrapper} ${isHero ? styles.isHero : ''}`}>
				{!!buttonVariant && (
					<>
						{showPlayButton && (
							<MediaControlButton className={styles.playButton} onClick={onPlayButtonClick} variant={buttonVariant} title={buttonTitle} />
						)}
						{children}
					</>
				)}
			</div>
		</div>
	);
}
