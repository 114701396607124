import { useEffect, useState } from 'react';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';

if (typeof window !== 'undefined') {
	(window as any).CommentsCount = {
		setCount,
	};
}
interface CountJSONPData {
	ref: string;
	html: string;
	count: number;
	id?: string | null;
}

const COUNT_MAP = new Map<string, { setters: ((count: number) => void)[]; value: undefined | number; script: HTMLScriptElement }>();

function setCount(data: CountJSONPData) {
	const counts = COUNT_MAP.get(data.ref);
	if (counts) {
		for (const setCount of counts.setters) {
			setCount(data.count);
		}
	}
}

export function useCommentsCount(articleId: string | undefined) {
	const { endpoints } = useEnvironment();
	const [count, setCount] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (articleId !== undefined) {
			if (COUNT_MAP.has(articleId)) {
				// if the article is already present in the count map only add a new setter and update the count with the stored value
				const counts = COUNT_MAP.get(articleId)!;
				counts.setters.push(setCount);
				setCount(counts.value);
			} else {
				// otherwise add the jsonp script to the document and initialize the count map
				const script = window.document.createElement('script');
				const url = new URL(`${endpoints.ENDPOINT_CORAL}/api/story/count.js`);
				url.searchParams.set('callback', 'CommentsCount.setCount');
				url.searchParams.set('id', articleId);
				url.searchParams.set('ref', articleId);

				script.src = url.toString();

				// Attach the script to the body.
				window.document.body.appendChild(script);

				COUNT_MAP.set(articleId, {
					setters: [setCount],
					value: undefined,
					script,
				});
			}

			return () => {
				const counts = COUNT_MAP.get(articleId);
				if (counts) {
					const index = counts.setters.findIndex((setter) => setter === setCount);
					if (index >= 0) {
						// remove handler from global count registry
						counts.setters.splice(index, 1);
					}

					// if no more handlers are defined, remove the script from the body and also remove the count data from
					// the global registry
					if (counts.setters.length === 0) {
						COUNT_MAP.delete(articleId);
						// remove script from body
						window.document.body.removeChild(counts.script);
					}
				}
			};
		} else {
			return undefined;
		}
	}, [articleId, endpoints.ENDPOINT_CORAL]);

	return count;
}
