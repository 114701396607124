import React from 'react';

import usePermanentSwitch from './usePermanentSwitch';

/**
 * If the browser has JavaScript (enabled), this will immediately fire and `shouldRender` will be true.
 */
const useOnlyRenderWithJS = () => {
	const [shouldRender, toggleShouldRender] = usePermanentSwitch(false);

	React.useEffect(() => {
		toggleShouldRender();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return shouldRender;
};

export default useOnlyRenderWithJS;
