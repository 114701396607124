import { useRouter } from 'next/router';
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';

import { Link } from '@/components/Link/Link';

import { useEnvironment } from '../context/EnvironmentContext';

/**
 * @see https://github.com/coralproject/talk/blob/c3c5a312eebaa528df2c419b3b2deed3336b8713/src/core/client/embed/Coral.ts#L13-L42
 */
interface CreateStreamEmbedConfig {
	/**
	 *  ID of a DOM element on the page into which the comment stream will be rendered.
	 */
	id: string;
	/**
	 * The root URL of the coral installation.
	 */
	rootURL: string;
	/**
	 * ID for the story. May alternately specify via `storyURL` or allow Coral to scrape
	 *  and determine automatically. If used without a `storyURL`, the comment stream will
	 *  use the canonical URL of the page it's running on as the `storyURL`.
	 */
	storyID?: string;
	/**
	 * URL for the story. May alternately specify via storyID or allow Coral to scrape
	 * and determine automatically.
	 */
	storyURL?: string;

	/** Allow setting className of embed container */
	containerClassName?: string;

	/**
	 * Render the comments when the scroll position aproaches the rendering position of the
	 * comments stream element
	 */
	autoRender?: boolean;

	/**
	 * customCSSURL is the URL of the custom CSS used to display on the frontend.
	 */
	customCSSURL?: string;

	/**
	 * disableDefaultFonts will turn off font-face loading of Coral's default fonts.
	 */
	disableDefaultFonts?: boolean;
}

interface StreamEmbed {
	render(): void;
	remove(): void;
	readonly rendered: boolean;
}

declare global {
	interface Window {
		Coral?: {
			createStreamEmbed(config: CreateStreamEmbedConfig): StreamEmbed;
			Talk: {
				render: (element: Element, { asset_id, talk }: { asset_id: string; talk: string; lazy: boolean }) => void;
			};
		};
	}
}

interface HookInput {
	articleRowId: string;
	ref: React.RefObject<HTMLElement>;
	/** Allow setting className of embed container */
	containerClassName?: string;
}

type LinkProps = ComponentProps<typeof Link>;

export const COMMENT_BUTTON_ID = 'comments';

export function useComments({ articleRowId, ref, containerClassName }: HookInput) {
	const router = useRouter();
	const streamEmbedRef = useRef<StreamEmbed>();
	const environment = useEnvironment();
	const endpoint = environment.endpoints.ENDPOINT_CORAL;
	const [isScriptLoaded, setIsScriptLoaded] = useState(false);

	const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);

	const updateState = useCallback(
		(url: string) => {
			const { hash, searchParams } = new URL(url, 'http://example.com');

			// query is not populated correctly when used in combination with Akamai. Therefore
			// we parse the URL again for commentID extraction to allow direct links to comments

			// if the path contains a COMMENT_BUTTON_ID hash show the comments
			// or if a link to a comment instance is clicked
			const isVisible = hash === `#${COMMENT_BUTTON_ID}` || searchParams.has('commentID');

			setIsVisible(isVisible);

			// toggle visibility if state changes
			if (ref?.current) {
				// display none is important for keyboard navigation. Elements with `display: none` are
				// not accessible via keyboard focus.
				ref.current.style.display = isVisible ? 'block' : 'none';
			}
		},
		[ref]
	);

	// component initialization and teardown
	useEffect(() => {
		updateState(router.asPath);

		// destroy embed only when the component is unmounted
		return () => {
			streamEmbedRef.current?.remove();
			streamEmbedRef.current = undefined;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		router.events.on('hashChangeComplete', updateState);
		router.events.on('routeChangeComplete', updateState);

		return () => {
			router.events.off('hashChangeComplete', updateState);
			router.events.off('routeChangeComplete', updateState);
		};
	}, [router, updateState]);

	// if visibility changes and the embed is not rendered already => render the embed
	useEffect(() => {
		const createStreamEmbed = window?.Coral?.createStreamEmbed;
		if (!streamEmbedRef.current && ref.current && isVisible && createStreamEmbed && isScriptLoaded) {
			// settings here will overwrite settings definde via the coral admin ui
			streamEmbedRef.current = createStreamEmbed({
				id: `coral-embed-stream-${articleRowId}`,
				storyID: articleRowId,
				autoRender: false,
				rootURL: endpoint,
				containerClassName,
				customCSSURL: `coral/custom-styles-v1.css`,
				// default fonts can not be disabled because the material icon font and maybe others are
				// necessary to display the stream correctly
				disableDefaultFonts: false,
			});
			streamEmbedRef.current.render();
		}
	}, [articleRowId, ref, containerClassName, endpoint, isVisible, isScriptLoaded]);

	let href: LinkProps['href'];

	if (!isVisible) {
		href = { hash: COMMENT_BUTTON_ID };
	} else {
		const { pathname } = new URL(router.asPath, 'http://example.com');

		href = { pathname, hash: '' };
	}

	const linkProps = {
		href,
		scroll: !isVisible,
		title: `Kommentare ${isVisible ? 'schließen' : 'öffnen'}`,
	};

	return {
		setIsCommentsScriptLoaded: setIsScriptLoaded,
		isVisible,
		linkProps,
	};
}
