import { logger } from '@/utils/logging/logger';

const getIsMediaOffline = (meta: string | null) => {
	let isMediaOffline = false;

	if (meta && typeof meta === 'string') {
		try {
			const parsedMetaInfo = JSON.parse(meta);

			if (parsedMetaInfo && parsedMetaInfo.offline) {
				isMediaOffline = true;
			}
		} catch (err: any) {
			logger.error(`error while parsing meta data for audio/video module: ${meta}`, 'getMediaStatus');
		}
	}

	return isMediaOffline;
};

export default getIsMediaOffline;
