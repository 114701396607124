import { StaticNormalizedNavigationNode } from '@/types/navigation';

export const NAVIGATION_ITEM_AUTHOR: StaticNormalizedNavigationNode = {
	category: 'funktionsseiten',
	id: 'authors',
	isNotVisitable: false,
	pageTitle: 'Autoren',
	pageType: 'Autorenseite',
	path: '/autoren',
	rowId: 'authors',
};

export const NAVIGATION_ITEM_CREDITS: StaticNormalizedNavigationNode = {
	id: 'credits',
	isNotVisitable: false,
	pageTitle: 'Credits',
	path: '/credits',
	rowId: 'credits',
};

export const NAVIGATION_ITEM_ERROR_NOT_FOUND: StaticNormalizedNavigationNode = {
	id: '404',
	isNotVisitable: false,
	pageTitle: '404 Fehlerseite',
	pageType: 'Fehlerseite',
	path: '/',
	rowId: '404',
};

export const NAVIGATION_ITEM_ERROR_SERVER: StaticNormalizedNavigationNode = {
	id: '500',
	isNotVisitable: false,
	pageTitle: '500 Fehlerseite',
	pageType: 'Fehlerseite',
	path: '/',
	rowId: '500',
};

export const NAVIGATION_ITEM_HEADLINES: StaticNormalizedNavigationNode = {
	id: 'schlagzeilen',
	category: 'funktionsseiten',
	isNotVisitable: false,
	pageTitle: 'Schlagzeilen',
	path: '/schlagzeilen',
	rowId: 'schlagzeilen',
};

export const NAVIGATION_ITEM_HOME: StaticNormalizedNavigationNode = {
	id: 'homepage',
	isNotVisitable: false,
	pageTitle: 'BR24 - Hier ist Bayern',
	pageType: 'board',
	path: '/',
	rowId: 'homepage',
	type: 'Rubrik',
};

export const NAVIGATION_ITEM_SEARCH: StaticNormalizedNavigationNode = {
	category: 'funktionsseiten',
	id: 'search',
	isNotVisitable: false,
	pageTitle: 'Suche',
	path: '/suche',
	rowId: 'search',
};

export const NAVIGATION_ITEM_SHORTNEWS: StaticNormalizedNavigationNode = {
	category: 'meldungen',
	id: 'shortnews',
	isNotVisitable: false,
	pageTitle: 'BR24 Meldungen',
	pageType: 'Meldungen',
	path: '/meldung',
	rowId: 'shortnews',
};

export const NAVIGATION_ITEM_TRAFFIC: StaticNormalizedNavigationNode = {
	category: 'funktionsseiten',
	id: 'traffic',
	isNotVisitable: false,
	pageTitle: 'Verkehrsmeldungen',
	path: '/verkehrsmeldungen',
	rowId: 'traffic',
};

export const NAVIGATION_ITEM_WEATHER: StaticNormalizedNavigationNode = {
	category: 'funktionsseiten',
	id: 'weather',
	isNotVisitable: false,
	pageTitle: 'Bayern-Wetter',
	path: '/bayern-wetter',
	rowId: 'weather',
};
