import { LivestreamStatus } from '@/types/global';

let logged__debug__getLivestreamStatus = false;

export const __debug__getLivestreamStatus = (fallback: LivestreamStatus | null) => {
	if (typeof window !== 'undefined') {
		const status = window.localStorage.getItem('br24-livestream-status');
		if (status === null) {
			return fallback;
		} else if (['FUTURE', 'NOW_LIVE', 'PAST'].includes(status)) {
			if (!logged__debug__getLivestreamStatus) {
				logged__debug__getLivestreamStatus = true;
			}
			return status as LivestreamStatus;
		}
	}

	return fallback;
};
