import { ALLOWED_IMAGESERVER_HOSTNAMES } from '@/shared/allowed-imageserver-hostnames';
import { resize } from '@/utils/image/resize';

type Image = {
	altText?: string | null;
};

type GetImageAltTextInput = {
	image?: Image | null;
	seoTitle?: string | null;
	title: string;
};

export const getImageAltText = ({ image, seoTitle, title }: GetImageAltTextInput): string => image?.altText || seoTitle || title;

export const getImageCopyright = (image: { copyright?: string | null } | null | undefined) => image?.copyright ?? null;

const isImageServerImage = (url: string) => ALLOWED_IMAGESERVER_HOSTNAMES.has(new URL(url).hostname);

export const getImageUrl = (image: { url?: string | null } | null | undefined, options?: { dontResize?: boolean }): string | undefined => {
	const { url } = image || {};

	if (!url || options?.dontResize || !isImageServerImage(url)) {
		return url ?? undefined;
	}

	return resize(url, 'landscape', 'xxl');
};
