import { differenceInMilliseconds, subMinutes, differenceInMinutes } from 'date-fns';
import { useState } from 'react';

import { useInterval } from '@/cutils/hooks/useInterval';
import { LivestreamStatus } from '@/types/global';

const ONE_MINUTE_IN_MS = 60000;

/**
 * Calculate the initial delay for updating the live display. This will prevent differing
 * displays based on page load times.
 *
 * Example:
 * If the livestream starts at 15:00:00 and Date.now returns 14:23:45 this will return 15000.
 * If the livestream starts at 15:00:23 and Date.now returns 14:23:45 this will return 38000.
 *
 * @param date the date to calculate the difference to.
 * @returns The number of milliseconds to the next "full" minute.
 */
function getInitialDelay(date: Date) {
	const now = new Date();
	const difference = differenceInMilliseconds(subMinutes(date, differenceInMinutes(date, now)), now);
	if (difference < 0) {
		return null;
	} else {
		return difference;
	}
}

/**
 * Calls the callback each minute before the livestream starts.
 *
 * @param callback the callback to be called
 * @param status the status of the livestream
 * @param isoStartDate the start date.
 */
export function useLivestreamCountdown(callback: () => void, status: LivestreamStatus, startDate: Date | null) {
	const [delay, setDelay] = useState(status === 'FUTURE' && startDate ? getInitialDelay(startDate) : null);

	useInterval(
		() => {
			callback();
			// set the delay to one minute after the first invocation
			setDelay(ONE_MINUTE_IN_MS);
		},
		// When set to `null`, we want start the interval.
		status === 'FUTURE' ? delay : null
	);
}
