import styles from './RichText.module.scss';

type Props = {
	className?: string;
	text?: string | null;
};

export function RichText({ text, className }: Props) {
	return <div className={`${styles.richText} ${className ?? ''}`} dangerouslySetInnerHTML={{ __html: text || '' }} />;
}
