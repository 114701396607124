// Types
import { OpenGraph, OpenGraphArticle } from 'next-seo/lib/types';

type BuildOpenGraphObjectInput = {
	articleAuthors?: string[] | null;
	articleExpirationTime?: string | null;
	articleModifiedTime?: string | null;
	articlePublishedTime?: string | null;
	articleSection?: string | null;
	articleTags?: string[] | null;
	description?: string | null;
	image?: string | null;
	imageAlt?: string | null;
	imageHeight?: number | null;
	imageWidth?: number | null;
	locale?: string | null;
	seoTitle?: string | null;
	siteName?: string | null;
	title?: string | null;
	type?: string | null;
	url?: string | null;
};

const buildOpenGraphImageArray = (input: BuildOpenGraphObjectInput) => {
	const { image, imageAlt, imageHeight, imageWidth } = input;

	if (!image && !imageAlt && !imageHeight && !imageWidth) {
		return undefined;
	}

	const openGraphImageObject = {
		url: image || '',
		width: imageWidth || undefined,
		height: imageHeight || undefined,
		alt: imageAlt || undefined,
	};

	return [openGraphImageObject];
};

const buildOpenGraphArticleObject = (input: BuildOpenGraphObjectInput): OpenGraphArticle | undefined => {
	const { articleAuthors, articleExpirationTime, articleModifiedTime, articlePublishedTime, articleSection, articleTags } = input;

	if (!articleAuthors && !articleExpirationTime && !articleModifiedTime && !articlePublishedTime && !articleSection && !articleTags) {
		return undefined;
	}

	const openGraphArticleObject = {
		authors: articleAuthors || undefined,
		expirationTime: articleExpirationTime || undefined,
		modifiedTime: articleModifiedTime || undefined,
		publishedTime: articlePublishedTime || undefined,
		section: articleSection || undefined,
		tags: articleTags || undefined,
	};

	return openGraphArticleObject;
};

const buildOpenGraphObject = (input: BuildOpenGraphObjectInput): OpenGraph => {
	const { description, locale, seoTitle, siteName, title, type, url } = input;

	const article = buildOpenGraphArticleObject(input);
	const images = buildOpenGraphImageArray(input);

	const openGraphObject = {
		article,
		description: description || undefined,
		images,
		locale: locale || undefined,
		site_name: siteName || undefined,
		title: title || seoTitle || undefined,
		type: type || undefined,
		url: url || undefined,
	};

	return openGraphObject;
};

export default buildOpenGraphObject;
