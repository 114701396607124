import { ImageObject, NewsMediaOrganization } from 'schema-dts';

import LogoImage from '../../../../../icons/site/android-chrome-192x192.png';

export function publisher(selfOrigin: string): Exclude<NewsMediaOrganization, string> {
	return {
		'@type': 'NewsMediaOrganization',
		name: 'BR24',
		logo: {
			'@type': 'ImageObject',
			url: `${selfOrigin}${LogoImage.src}`,
			height: { '@type': 'QuantitativeValue', value: LogoImage.height, unitCode: 'px' },
			width: { '@type': 'QuantitativeValue', value: LogoImage.width, unitCode: 'px' },
		},
		url: 'https://www.br.de',
		foundingDate: '1949-01-25',
		sameAs: [
			'https://www.facebook.com/BR24/',
			'https://twitter.com/BR24',
			'https://www.instagram.com/br24/',
			'https://www.youtube.com/channel/UCcweJsCV2TUP_kzMX25U-oQ',
			'https://de.linkedin.com/company/bayerischer-rundfunk',
			'https://de.wikipedia.org/wiki/BR24',
			'https://www.pinterest.de/BR24news/',
		],
	};
}

export function organization(selfOrigin: string) {
	return {
		'@id': 'https://www.br.de/#publisher',
		...publisher(selfOrigin),
	};
}

export function defaultImage(selfOrigin: string): ImageObject {
	const width = 800;
	const height = (width * 9) / 16;

	return {
		'@type': 'ImageObject',
		caption: 'Nachrichten, Fakten und Hintergrundinformationen zu Politik, Wirtschaft, Kultur und Sport von BR24.',
		height: { '@type': 'QuantitativeValue', value: height, unitCode: 'px' },
		width: { '@type': 'QuantitativeValue', value: width, unitCode: 'px' },
		url: `https://img.br.de/c5f22600-f088-45d5-bccc-33c88a2e914d.png?w=${width}&h=${height}`,
		copyrightHolder: organization(selfOrigin),
	};
}
