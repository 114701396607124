import { IconSystemButton } from '@/components/base/IconButton/IconSystemButton';
import useOnlyRenderWithJS from '@/cutils/hooks/useOnlyRenderWithJS';
import { MediaControlButtonVariant } from '@/types/enums';

import styles from './MediaControlButton.module.scss';

type Props = {
	variant: MediaControlButtonVariant;
	onClick?: () => void;
	className?: string | undefined;
	title?: string;
};

export function MediaControlButton({ variant, onClick, className, title }: Props) {
	const isJavascriptEnabled = useOnlyRenderWithJS();

	if (!isJavascriptEnabled) {
		return null;
	}

	let buttonTitle: string;

	switch (variant) {
		case MediaControlButtonVariant.Audio:
			buttonTitle = `Audio abspielen`;
			break;

		case MediaControlButtonVariant.Livestream:
			buttonTitle = `Livestream abspielen`;
			break;

		case MediaControlButtonVariant.Video:
			buttonTitle = `Video abspielen`;
			break;
	}

	return (
		<IconSystemButton
			type="primary"
			icon="play"
			variant="filled"
			className={`${styles.playButton} ${className ? className : ''}`}
			aria-label={title ? title : buttonTitle}
			title={title ? title : buttonTitle}
			onClick={onClick}
		/>
	);
}
