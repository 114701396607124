import { ReactNode } from 'react';

import { Link } from '@/components/Link/Link';

import styles from './ArticleItemImage.module.scss';

type Props = {
	image: ReactNode;
	path: string;
	title: string;
};

const ArticleItemImage = ({ image, path, title }: Props) => {
	return (
		<Link className={styles.imageLink} title={title} href={path} tabIndex={-1}>
			{image}
		</Link>
	);
};

export default ArticleItemImage;
