import { BroadcastEvent, MediaObject, PublicationEvent, WithContext } from 'schema-dts';

import SchemaOrg from '@/components/SchemaOrg';
import formatSecondsToIsoDuration from '@/shared/utils/time/formatSecondsToIsoDuration';

type BuildMediaStructuredDataInput = {
	description?: string;
	duration: number | null;
	publicationDate?: string | null;
	publication?: BroadcastEvent;
	thumbnail?: string | null;
	title?: string | null;
	url: string;
	type: 'audio' | 'video';
};

const buildMediaStructuredData = (media: BuildMediaStructuredDataInput) => {
	const { description, duration, publicationDate, thumbnail, title, type, url, publication } = media;

	if (!duration || !thumbnail || !title || !url) {
		return null;
	}

	const publicationWithDefaults: PublicationEvent = {
		...publication,
		'@type': publication?.['@type'] ?? 'BroadcastEvent',
	};

	const schema: WithContext<MediaObject> = {
		'@context': 'https://schema.org',
		'@type': type === 'audio' ? 'AudioObject' : 'VideoObject',
		contentUrl: url,
		description: description || title,
		duration: formatSecondsToIsoDuration(duration),
		name: title,
		thumbnailUrl: thumbnail,
		uploadDate: publicationDate || undefined,
		publication: publication ? publicationWithDefaults : undefined,
	};

	return <SchemaOrg schema={schema} />;
};

export default buildMediaStructuredData;
