const PRESETS = {
	square: {
		width: 1600,
		height: 1600,
	},
	portrait: {
		width: 1600,
		height: 1200,
	},
	landscape: {
		width: 1600,
		height: 900,
	},
} as const;

const SCALE = {
	xs: 1 / 9,
	s: 1 / 5,
	m: 1 / 4,
	l: 1 / 3,
	xl: 1 / 2,
	xxl: 1,
	xxxl: 1.3,
	xsl: 3 / 4,
};

type OptionsPresets = keyof typeof PRESETS;
type Scale = keyof typeof SCALE;

export function resize(url: string, options: OptionsPresets, size: Scale = 'xxl'): string {
	const width = PRESETS[options].width * SCALE[size];
	const height = PRESETS[options].height * SCALE[size];

	const _url = new URL(url);

	_url.searchParams.set('w', width.toString());

	_url.searchParams.set('h', height.toString());

	return _url.href;
}
