// Converts seconds into ISO8601 duration string (without weeks).
// https://en.wikipedia.org/wiki/ISO_8601#Durations
const formatSecondsToIsoDuration = (input: number) => {
	const yearInSeconds = 31536000;
	const monthInSeconds = 2628000;
	const dayInSeconds = 86400;
	const hourInSeconds = 3600;
	const minuteInSeconds = 60;

	let seconds = input;

	const years = Math.floor(seconds / yearInSeconds);
	seconds -= years * yearInSeconds;

	const months = Math.floor(seconds / monthInSeconds);
	seconds -= months * monthInSeconds;

	const days = Math.floor(seconds / dayInSeconds);
	seconds -= days * dayInSeconds;

	const hours = Math.floor(seconds / hourInSeconds);
	seconds -= hours * hourInSeconds;

	const minutes = Math.floor(seconds / minuteInSeconds);
	seconds -= minutes * minuteInSeconds;

	// Build string

	let duration = 'P';

	if (years > 0) {
		duration = `${duration}${years}Y`;
	}

	if (months > 0) {
		duration = `${duration}${months}M`;
	}

	if (days > 0) {
		duration = `${duration}${days}D`;
	}

	if (hours > 0 || minutes > 0 || seconds > 0) {
		duration = `${duration}T`;
	}

	if (hours > 0) {
		duration = `${duration}${hours}H`;
	}

	if (minutes > 0) {
		duration = `${duration}${minutes}M`;
	}

	if (seconds > 0) {
		duration = `${duration}${seconds}S`;
	}

	return duration;
};

export default formatSecondsToIsoDuration;
