import { MediaCollection, PlayerConfiguration } from '@pub.tech/ardplayer-react-core';
import { differenceInMilliseconds, isAfter, isBefore, parseISO } from 'date-fns';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import AspectRatio from '@/components/AspectRatio';
import buildImageHoverTitle from '@/cutils/builders/buildImageHoverTitle';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { MediaContextPlaybackStatus } from '@/cutils/context/MediaContext';
import { __debug__getLivestreamStatus } from '@/cutils/debug';
import { useInterval } from '@/cutils/hooks/useInterval';
import { useMediaState } from '@/cutils/hooks/useMediaContext';
import buildMediaStructuredData from '@/cutils/seo/schema-org/buildModuleMediaStructuredData';
import { MediaControlButtonVariant } from '@/types/enums';
import { LivestreamStatus } from '@/types/global';
import { resize } from '@/utils/image/resize';
import { useTracktor } from 'react-tracktor';

import { MediaOverlay } from '../Shared/MediaOverlay';
import { useLivestreamCountdown } from '../Shared/useLivestreamCountdown';

import styles from './index.module.scss';

const MediaLivestreamStatus = dynamic(() => import('../Shared/MediaLivestreamStatus'), { ssr: false });

const ArdPlayerReact = dynamic(() => import('../ArdPlayer').then(({ ArdPlayer }) => ArdPlayer), {
	ssr: false,
});

interface LiveConfig {
	/**
	 * Längen-Angabe des DVR-Fensters
	 *
	 * Setting this value to a number > 0 in combination with enabling
	 * the `isDvrEnabled` setting in the player configuration will switch
	 * the player into the live mode. The value of this option has no other
	 * effect at the moment.
	 */
	dvrWindowSeconds: number;
}

type Props = {
	copyright?: string | null;
	customTitle?: string | null;
	customVars?: object;
	isHero?: boolean;
	isPriority?: boolean;
	isRundschau?: boolean;
	liveStreamHlsUrl: string | null;
	livestreamStartDate: string | null;
	livestreamEndDate: string | null;
	livestreamStatus: LivestreamStatus;
	publicationDate?: string | null;
	mediaVars?: object;
	moduleId?: string;
	thumbnailAltText?: string | null;
	thumbnailCopyright?: string | null;
	thumbnailLink?: string | null;
	thumbnailUrl?: string | null;
	title?: string | null;
	videos?: any | null;
	onPlayStatusChange?(status: MediaContextPlaybackStatus): void;
	enableZoomEffect?: boolean;
	sizes: string;
	playButtonTitle?: string;
};

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

/**
 * Calculates if the starting soon state is active
 */
function calculateIsLivestreamStartingSoon(startDate: Date | null) {
	if (startDate === null) {
		return false;
	}

	const timeTillLiveStreamStartInMs = differenceInMilliseconds(startDate, new Date());

	return timeTillLiveStreamStartInMs >= 0 && timeTillLiveStreamStartInMs <= FIVE_MINUTES_IN_MS;
}

/**
 * Calculates the current livestream status. This is necessary as the status changes over time and the display of the
 * status should be correctly displayd even if the page is not refreshed. The livestream status returned by the query
 * will change over time as the livestream goes through its predefined lifecycle.
 *
 * @param startDate
 * @param endDate
 */
function getLiveStreamStatus(startDate: Date | null, endDate: Date | null): LivestreamStatus | null {
	let status: LivestreamStatus | null = null;

	const now = new Date();

	if (startDate !== null && endDate !== null && isAfter(now, startDate) && isBefore(now, endDate)) {
		status = 'NOW_LIVE';
	} else if (startDate !== null && isBefore(now, startDate)) {
		status = 'FUTURE';
	} else if (endDate !== null && isAfter(now, endDate)) {
		status = 'PAST';
	} else {
		status = null;
	}

	return __debug__getLivestreamStatus(status);
}

function calculateTimeUntilEndOfStream(firstRenderDate: Date | undefined, endDate: Date): number | null {
	if (!firstRenderDate) {
		return null;
	}

	const diff = differenceInMilliseconds(endDate, firstRenderDate);
	if (diff > 0) {
		return diff;
	} else {
		return null;
	}
}

function getPlayStatusClassName(mediaPlaybackStatus: MediaContextPlaybackStatus) {
	switch (mediaPlaybackStatus) {
		case MediaContextPlaybackStatus.PAUSED:
			return styles.paused;
		case MediaContextPlaybackStatus.STARTED:
			return styles.playing;
		case MediaContextPlaybackStatus.STOPPED:
			return styles.stopped;
	}
}

function getPlayerStatusFromMediaState(mediaStatus: MediaContextPlaybackStatus) {
	switch (mediaStatus) {
		case MediaContextPlaybackStatus.PAUSED:
			return 'pause';
		case MediaContextPlaybackStatus.STARTED:
			return 'play';
		case MediaContextPlaybackStatus.STOPPED:
			return 'stop';
	}
}

const playerConfig: PlayerConfiguration = {
	generic: {
		isAutoplay: false,
		isDvrEnabled: true,
	},
	web: {
		// this is the base url for all ard player related assets
		// see next.config.js for details on where they are copied from
		baseUrl: 'ard-player/',
	},
};

// ------------------------------------------------------------
// THESE ARE THE STATES WE NEED TO HANDLE:
// 1) Currently LIVE — Start & End Date
// 2) If FUTURE — show start time & HIDE Play button
// 3) If PAST AND no video files — show no longer available
// 4) If PAST AND video files — show video
// 5) Special case Rundschau : in den 15min nach LIVE --> use old episode. 15min after LIVE --> use new episode
// ------------------------------------------------------------
export function MediaLivestream({
	copyright,
	customTitle,
	customVars,
	isHero,
	isPriority,
	isRundschau,
	liveStreamHlsUrl,
	livestreamStartDate,
	livestreamEndDate,
	livestreamStatus: initialLivestreamStatus,
	mediaVars,
	moduleId,
	thumbnailAltText,
	thumbnailCopyright,
	thumbnailLink,
	thumbnailUrl,
	title,
	videos,
	onPlayStatusChange,
	publicationDate,
	enableZoomEffect = false,
	sizes,
	playButtonTitle,
}: Props) {
	const startDate = livestreamStartDate ? parseISO(livestreamStartDate) : null;
	const endDate = livestreamEndDate ? parseISO(livestreamEndDate) : null;
	const durationInSecond = startDate && endDate ? Math.max(endDate.getTime() - startDate.getTime(), 0) / 1000 : null;
	const [livestreamStatus, setLivestreamStatus] = useState(getLiveStreamStatus(startDate, endDate) ?? initialLivestreamStatus);
	const firstRenderDate = useRef<Date>();
	const [hasPlayerStarted, setHasPlayerStarted] = useState(false);
	const [playerInitialized, setPlayerInitialized] = useState(false);

	useEffect(() => {
		firstRenderDate.current = new Date();
	}, []);

	const { images } = useEnvironment();
	const { trackEvent } = useTracktor({});

	const [isLiveStreamStartingSoon, setIsLivestreamStartingSoon] = useState(calculateIsLivestreamStartingSoon(startDate));

	const { isPlayingOrPaused, isPlaying, mediaPlaybackStatus, mediaStart, mediaPause, mediaStop } = useMediaState({
		isVideo: true,
		shouldTrackPlay: true,
	});

	useEffect(() => {
		if (isPlaying) {
			setPlayerInitialized(true);
		}
	}, [isPlaying]);

	useLivestreamCountdown(
		() => {
			setIsLivestreamStartingSoon(calculateIsLivestreamStartingSoon(startDate));
			// set the livestream state during the countdown phase
			setLivestreamStatus(getLiveStreamStatus(startDate, endDate) ?? initialLivestreamStatus);
		},
		livestreamStatus,
		startDate
	);

	// set the correct livestream state after the livestream is over
	useInterval(
		() => {
			setLivestreamStatus(getLiveStreamStatus(startDate, endDate) ?? initialLivestreamStatus);
		},
		endDate ? calculateTimeUntilEndOfStream(firstRenderDate.current, endDate) : null
	);

	const isRundschauAndCurrentlyLive = isRundschau && livestreamStatus === 'NOW_LIVE';

	const showPlayButton =
		// This case can never happen for Rundschau LiveStreams, as there are always videos available.
		(livestreamStatus === 'PAST' && videos?.length > 0) || (!isRundschau && isLiveStreamStartingSoon) || livestreamStatus === 'NOW_LIVE';

	const mediaTitle = buildImageHoverTitle({
		altText: isPlayingOrPaused ? title : thumbnailAltText,
		copyright: isPlayingOrPaused ? copyright : thumbnailCopyright,
		variant: isPlayingOrPaused ? 'media' : 'bild',
	});

	const onPlayerStatusChange = useCallback(
		(status: 'pause' | 'stop' | 'play') => {
			switch (status) {
				case 'pause':
					onPlayStatusChange?.(MediaContextPlaybackStatus.PAUSED);

					// Current Tracking Flow :
					// trackEvent --> useTracktor --> TracktorContext --> TracktorProvider --> google-tag-manager/GoogleTagManagerTrackingAdapter

					trackEvent({
						data: {
							customVars: { ...customVars, 6: '[Video-Livestream-Pause]' },
							mediaVars: { ...mediaVars, action: 'EVENT_PAUSE_STREAM' },
							id: moduleId,
						},
						event: { type: 'av.pause' },
					});
					mediaPause();
					break;

				case 'stop':
					onPlayStatusChange?.(MediaContextPlaybackStatus.STOPPED);
					trackEvent({
						data: {
							customVars: { ...customVars, 6: '[Video-Livestream-Stop]' },
							mediaVars: { ...mediaVars, action: 'EVENT_STOP_STREAM' },
							id: moduleId,
						},
						event: { type: 'av.stop' },
					});
					mediaStop();
					break;

				case 'play':
					onPlayStatusChange?.(MediaContextPlaybackStatus.STARTED);
					if (!hasPlayerStarted) {
						trackEvent({
							data: {
								customVars: { ...customVars, 6: '[Video-Livestream-Real-Start]' },
								mediaVars: { ...mediaVars, action: 'EVENT_INITIAL_FRAME' },
								id: moduleId,
							},
							event: { type: 'av.start' },
						});
						setHasPlayerStarted(true);
					} else {
						trackEvent({
							data: {
								customVars: { ...customVars, 6: '[Video-Livestream-Start]' },
								mediaVars: { ...mediaVars, action: 'EVENT_PLAY_STREAM' },
								id: moduleId,
							},
							event: { type: 'av.play' },
						});
					}

					mediaStart();
					break;
			}
		},
		[onPlayStatusChange, trackEvent, customVars, mediaVars, moduleId, mediaPause, mediaStop, hasPlayerStarted, mediaStart]
	);

	const onBuffering = useCallback(() => {
		trackEvent({
			data: {
				customVars: { ...customVars, 6: '[Video-Livestream-Buffering]' },
				mediaVars: { ...mediaVars, action: 'EVENT_BUFFERING' },
				id: moduleId,
			},
			event: { type: 'av.buffer.start' },
		});
	}, [customVars, mediaVars, moduleId, trackEvent]);

	const mediaCollection: MediaCollection = useMemo(() => {
		let streamsMedia;

		let live: LiveConfig | undefined = undefined;

		// the video hls profile
		// @ videoFiles(filter: { videoProfile: { id: { eq: "av:http://ard.de/ontologies/audioVideo#VideoProfile_HLS" } } }) {
		// is always available, when there are videos available. Therefore i do not have to map over the videoFiles or check if more videoFiles are available. We can just use the first one. There will always be one.
		if (videos && videos.length > 0 && !isRundschauAndCurrentlyLive) {
			streamsMedia = videos?.map((video: any) => {
				return {
					url: video.publicLocation,
					mimeType: 'application/vnd.apple.mpegurl',
					isAdaptiveQualitySelectable: true,
					// This feature (maxHResolutionPx) is currently not being used, as it is unnecessary to manually set the resolution, since the HLS stream is adaptive and automatically adjusts to the resolution of the device.
					maxHResolutionPx: video?.videoProfile?.width,
					aspectRatio: '16:9',
					audios: [
						{
							kind: 'standard',
							languageCode: 'de',
						},
					],
				};
			});
		} else {
			// in case of the stream being a live stream that has not ended
			streamsMedia = [
				{
					url: liveStreamHlsUrl,
					mimeType: 'application/vnd.apple.mpegurl',
					isAdaptiveQualitySelectable: true,
					audios: [
						{
							kind: 'standard',
							languageCode: 'de',
						},
					],
				},
			];

			live = {
				dvrWindowSeconds: 600,
			};
		}

		return {
			meta: {
				title: '',
				images: [
					{
						title: 'Posterframe',
						kind: 'preview',
						url: thumbnailUrl || images.FALLBACK_IMAGE,
					},
				],
			},
			streams: [
				{
					kind: 'main',
					media: streamsMedia,
				},
			],
			live,
		};
	}, [videos, isRundschauAndCurrentlyLive, thumbnailUrl, images.FALLBACK_IMAGE, liveStreamHlsUrl]);

	const mediaPlaybackStatusClass = getPlayStatusClassName(mediaPlaybackStatus);

	const structuredData = useMemo(
		() =>
			buildMediaStructuredData({
				duration: Math.ceil(durationInSecond || 0),
				publicationDate,
				thumbnail: thumbnailUrl ? resize(thumbnailUrl, 'landscape', 'xl') : undefined,
				title,
				type: 'video',
				url: liveStreamHlsUrl || videos?.[0]?.url,
				publication: {
					'@type': 'BroadcastEvent',
					name: title ?? 'Next Broadcast',
					isLiveBroadcast: true,
					startDate: livestreamStartDate ?? '',
					endDate: livestreamEndDate ?? '',
				},
			}),
		[durationInSecond, publicationDate, thumbnailUrl, title, liveStreamHlsUrl, videos, livestreamStartDate, livestreamEndDate]
	);

	return (
		<AspectRatio as="figure" className={mediaPlaybackStatusClass}>
			<div>
				{/* Injects script tags with structured data. */}
				{structuredData}
				<MediaOverlay
					buttonVariant={MediaControlButtonVariant.Livestream}
					isHero={!!isHero}
					showPlayButton={showPlayButton}
					isPriority={isPriority}
					onPlayButtonClick={mediaStart}
					thumbnailAltText={thumbnailAltText}
					thumbnailCopyright={thumbnailCopyright}
					thumbnailLink={thumbnailLink}
					thumbnailTitle={mediaTitle}
					thumbnailUrl={thumbnailUrl}
					className={styles.overlay}
					enableZoomEffect={enableZoomEffect}
					sizes={sizes}
					buttonTitle={playButtonTitle}
				>
					{!!customTitle && (
						<div className={styles.videoTitleRoot}>
							<div className="heading3">{customTitle}</div>
						</div>
					)}

					{startDate && livestreamStatus && (
						<MediaLivestreamStatus startDate={startDate} status={livestreamStatus} useCustomDesign={!!customTitle} />
					)}
				</MediaOverlay>

				{playerInitialized ? (
					<ArdPlayerReact
						className={styles.player}
						mediaCollection={mediaCollection}
						playerConfiguration={playerConfig}
						onInternalStatusChange={onPlayerStatusChange}
						onBuffering={onBuffering}
						requestedStatus={getPlayerStatusFromMediaState(mediaPlaybackStatus)}
					/>
				) : null}
			</div>
		</AspectRatio>
	);
}
